/* eslint-disable no-alert */
<script>
import emailjs from '@emailjs/browser'

export default {
  data() {
    return {
      return: {
        name: ''
      }
    }
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    sendEmail() {
      // emailjs.sendForm('service_wgn7hps', 'template_e0785gq', this.$refs.form, 'W9ct-FhuQsveU39VZ')
      //   .then((result) => {
      //     // eslint-disable-next-line no-alert
      //     alert('SUCCESS!', result.text)
      //     this.$refs.form.reset()
      //   }, (error) => {
      //     // eslint-disable-next-line no-alert
      //     alert('FAILED...', error.text)
      //   })
      console.log('send')
    }
  }
}
</script>

<template>
  <section
      class="content content-container"
    >
      <h2 class="title">Any questions? <span class="highlight">Contact us</span></h2>

    <form
      class="inputs"
      ref="form"
      @submit.prevent="sendEmail">
      <div class="inputBlock">
        <span class="inputTitle">First name</span>
        <input
          class="input"
          type="text"
          name="user_name"
          placeholder="Enter your first name"
        />
      </div>

      <div class="inputBlock">
        <span class="inputTitle">Last name</span>
        <input
          class="input"
          type="text"
          name="user_last_name"
          placeholder="Enter your last name"
        />
      </div>

      <div class="inputBlock fullWidth">
      <!-- <div class="inputBlock"fullWidth> -->
        <span class="inputTitle">Email</span>
        <input
          class="input"
          type="text"
          name="user_email"
          placeholder="Enter your email"
        />
      </div>

      <div class="inputBlock fullWidth">
      <!-- <div class="inputBlock"fullWidth> -->
        <span class="inputTitle">Message</span>
        <textarea
          class="input"
          rows="5"
          name="message"
          placeholder="Type your message here"
        />
      </div>

      <div
        class="button fullWidth"
        @click="this.sendEmail()">
        <p class="buttonText">submit</p>

        <img
          class="icon"
          src="@/assets/images/svg/angle-arrow.svg"
          alt="achieve">
    </div>
    </form>
  </section>
</template>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: get-vw-pc(90px);
  padding-bottom: get-vw-pc(110px);
}

.title {
  color: #000;
  font-family: Century Gothic;
  font-size: get-vw-pc(80px);
  font-style: normal;
  font-weight: 700;
  line-height: 117.15%;
}

.highlight {
  background: #FBDA6A;
  font-weight: 400;
  padding-right: get-vw-pc(21px);
}

.inputs {
    display: grid;
    grid-template: auto / 1fr 1fr;
    grid-gap: get-vw-pc(24px);
    margin-top: get-vw-pc(97px);
    width: get-vw-pc(844px);
}

.fullWidth {
    grid-column: span 2;
}

.inputBlock {
    display: flex;
    flex-direction: column;
}

.inputTitle {
  color: #000;
  /* H_4 Menu text */
  font-family: Inter;
  font-size: get-vw-pc(24px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input {
  border-radius: get-vw-pc(8px);
  border: get-vw-pc(4px) solid var(--black, #000);
  resize: none;
  margin-top: get-vw-pc(16px);
  font-size: get-vw-pc(24px);
  padding: get-vw-pc(24px);
}

.button {
  margin-top: get-vw-pc(40px);
  border-radius: get-vw-pc(8px);
  background: #FCDA6A;
  width: 100%;
  padding: get-vw-pc(24px) 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.buttonText {
  color: #000;
  font-family: Inter;
  font-size: get-vw-pc(24px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.icon {
  width: get-vw-pc(13px);
  height: get-vw-pc(14px);
  margin-left: get-vw-pc(14px);
}
</style>

function sendEmail() {
  throw new Error('Function not implemented.')
}
